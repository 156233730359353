@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.eot");
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Regular.woff2") format("woff2"),
    url("Gilroy-Regular.woff") format("woff"),
    url("Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraboldItalic.eot");
  src: local("Gilroy Extrabold Italic"), local("Gilroy-ExtraboldItalic"),
    url("Gilroy-ExtraboldItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-ExtraboldItalic.woff2") format("woff2"),
    url("Gilroy-ExtraboldItalic.woff") format("woff"),
    url("Gilroy-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Bold.woff2") format("woff2"),
    url("Gilroy-Bold.woff") format("woff"),
    url("Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("Gilroy-Black.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Black.woff2") format("woff2"),
    url("Gilroy-Black.woff") format("woff"),
    url("Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Semibold.eot");
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("Gilroy-Semibold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Semibold.woff2") format("woff2"),
    url("Gilroy-Semibold.woff") format("woff"),
    url("Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.eot");
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Medium.woff2") format("woff2"),
    url("Gilroy-Medium.woff") format("woff"),
    url("Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-MediumItalic.eot");
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"),
    url("Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-MediumItalic.woff2") format("woff2"),
    url("Gilroy-MediumItalic.woff") format("woff"),
    url("Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-BlackItalic.eot");
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("Gilroy-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-BlackItalic.woff2") format("woff2"),
    url("Gilroy-BlackItalic.woff") format("woff"),
    url("Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-RegularItalic.eot");
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-RegularItalic.woff2") format("woff2"),
    url("Gilroy-RegularItalic.woff") format("woff"),
    url("Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiboldItalic.eot");
  src: local("Gilroy Semibold Italic"), local("Gilroy-SemiboldItalic"),
    url("Gilroy-SemiboldItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-SemiboldItalic.woff2") format("woff2"),
    url("Gilroy-SemiboldItalic.woff") format("woff"),
    url("Gilroy-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-HeavyItalic.eot");
  src: local("Gilroy Heavy Italic"), local("Gilroy-HeavyItalic"),
    url("Gilroy-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-HeavyItalic.woff2") format("woff2"),
    url("Gilroy-HeavyItalic.woff") format("woff"),
    url("Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Extrabold.eot");
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("Gilroy-Extrabold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Extrabold.woff2") format("woff2"),
    url("Gilroy-Extrabold.woff") format("woff"),
    url("Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-BoldItalic.eot");
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"),
    url("Gilroy-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-BoldItalic.woff2") format("woff2"),
    url("Gilroy-BoldItalic.woff") format("woff"),
    url("Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Heavy.eot");
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Heavy.woff2") format("woff2"),
    url("Gilroy-Heavy.woff") format("woff"),
    url("Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
