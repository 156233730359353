.main {
  margin: 0 auto;
  padding: 85px 0 45px;
}

.main__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Gilroy", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.main__thumb {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
}

.main__item {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 392px;
  width: 100%;
  height: 280px;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(360deg, #ff2b2b 7.83%, #971549 110.01%);

  border: 2px solid #e99898;
}

.fire {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 392px;
  width: 100%;
  height: 280px;
  border-radius: 20px;
  background-image: url("../assets/fire.png");
  background-repeat: no-repeat;
  background-position: top;
}

.main__middle {
  padding: 5px 17px;
  height: 103px;
  background: #8c1439;
  box-shadow: 0px 4px 4px 0px #5e142b66 inset;
  border-radius: 20px;
  width: 100%;
}

.main__promo--wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.main__text--top {
  font-family: "Gilroy", sans-serif;

  color: #ffffff;

  font-size: 36px;
  font-weight: 800;
  line-height: 44.1px;
  letter-spacing: -0.035em;
}

.main__text--bottom {
  font-family: "Gilroy", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 44.1px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #fdf351;
}

.main__text--sc {
  font-size: 31px;
}

.main__prize--list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
}

.main__prize--item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
}

.main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  width: 100%;
  height: 67px;
  border-radius: 20px;
  background: linear-gradient(180deg, #ffe924 7.62%, #fff741 100%);

  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 36.75px;
  letter-spacing: -0.035em;
  text-align: center;
  padding-top: 5px;

  color: #8c1439;

  text-transform: uppercase;
  cursor: pointer;
}
.main__stars {
  width: 97px;
}

.main__info--svg {
  width: 65px;
  height: 31px;
}

.main__top--title {
  font-family: "Gilroy", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 44.1px;
  letter-spacing: -0.035em;
  color: #ffffff;
}

.main__wrapper--top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main__logo {
  width: 70px;
  height: 70px;
}

.main__wrapper--two {
  display: flex;
  align-items: center;
  gap: 16px;
}

.main__fade {
  max-width: 170px;
  width: 100%;
}

.main__fade--text {
  font-family: "Gilroy", sans-serif;
  color: #ffffff;

  font-size: 24px;
  font-weight: 800;
  line-height: 29.4px;
  letter-spacing: -0.035em;
  text-align: center;
}

.main__fade--promo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 170px;
  width: 100%;
  height: 29px;
  background: linear-gradient(180deg, #ffe924 7.62%, #fff741 100%);
  border-radius: 10px;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 29.4px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #8c1439;
}

.main__text--ua {
  font-size: 32px;
}

@media screen and (max-width: 405px) {
  .main__top--title--super {
    font-size: 32px;
  }
}

@media screen and (max-width: 385px) {
  .main__text--ua {
    font-size: 30px;
  }

  .main__top--title--super {
    font-size: 28px;
  }
}

@media screen and (max-width: 374px) {
  .main__text--top {
    font-size: 26px;
  }

  .main__text--sc {
    font-size: 26px;
  }

  .main__promo--wrapper {
    gap: 11px;
  }

  .main__fade {
    max-width: 145px;
  }

  .main__text--bottom {
    font-size: 32px;
  }
}

@media screen and (max-width: 370px) {
  .main__top--title {
    font-size: 26px;
  }

  // .main__logo--ua {
  // }

  .main__wrapper--super {
    gap: 12px;
  }
}

@media screen and (max-width: 360px) {
  .main__top--title {
    font-size: 26px;
  }
}

@media screen and (max-width: 350px) {
  .main__prize--top {
    font-size: 21px;
  }

  .main__top--title--super {
    font-size: 22px;
  }

  .main__prize--bottom {
    font-size: 13px;
  }

  .main__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 330px) {
  .main__text--topSmaller {
    font-size: 39px;
  }

  .main__top--title--super {
    font-size: 21px;
  }

  .main__text--ua {
    font-size: 24px;
  }

  .main__title--ua {
    font-size: 25px;
  }
}

@media screen and (min-width: 1000px) {
  .main__thumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
}
